<template>
  <div>
    <div class="top-div" style="margin: 30px;">
      <div style="margin-bottom: 20px;">
        <span style="font-size: 15px;font-weight: bold;">目标排名</span>
        <el-cascader
            ref="cascader"
            size="mini"
            v-model="choiceDept"
            placeholder="请选择部门"
            style="width: 160px;margin-left: 20px"
            :options="deptList"
            collapse-tags
            collapse-tags-tooltip
            clearable
            :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"/>
        <el-date-picker
            size="mini"
            :clearable="false"
            style="width: 120px;margin-left: 20px"
            v-model="rankingChoiceDate"
            @change="choiceDate('ranking')"
            type="month"
            placeholder="请选择月份"
        />
          <el-radio-group style="margin-left: 40px;" v-model="ranking.sort">
            <el-radio :label="1"  @click="setQuery(1)" size="small">完成率</el-radio>
            <el-radio :label="2"  @click="setQuery(2)" size="small">已完成</el-radio>
          </el-radio-group>

      </div>
      <div v-if="rankingLogin == false" class="flex-around">
        <div  class="ranking-item" v-for="(item,index) in visibleRanking" :key="index">
            <div style="position: relative">
              <div style="text-align: center;font-weight: bold;margin-bottom: 10px">
                  {{item.coachName}}
                  <span v-if="item.sort > 3" style="font-size: 10px;font-weight: normal"> ~ {{item.sort}}</span>
              </div>
              <el-image class="sort" v-if="item.sort == 1" :src="require('@/assets/img/1.png')" />
              <el-image class="sort" v-if="item.sort == 2" :src="require('@/assets/img/奖杯1-2.png')" />
              <el-image class="sort" v-if="item.sort == 3" :src="require('@/assets/img/奖杯1-3.png')" />
              <div style="font-size: 13px">目标：{{item.targetNumber}}</div>
              <div style="display:flex;margin-top: 10px">
                <div style="width: 90%">
                  <el-progress
                      :percentage="item.accomplishRate > 100 ? 100 : item.accomplishRate"
                      :text-inside="true"
                      :stroke-width="16">
                    <span>{{item.accomplishTargetNumber}}</span>
                  </el-progress>
                </div>
                <div style="font-size: 12px;margin-left: 10px;">
                  {{item.accomplishRate}}%
                </div>
              </div>
            </div>
          </div>
      </div>
      <div v-if="rankingLogin == true" class="items">
        <div v-for=" (item,index) in 5" :key="index" style="text-align: center" class="item">
          <el-skeleton :rows="0" animated />
          <div style="text-align: left;margin: 10px 0">
            <el-skeleton-item variant="text" style="width: 40%;" />
          </div>
          <div style="display: flex;align-items: center;margin-top: 14px">
            <el-skeleton-item variant="text" style="margin-right: 16px" />
            <el-skeleton-item variant="text" style="width: 30%" />
          </div>
        </div>
      </div>
      <div v-if="visibleRanking.length<1 && rankingLogin == false">
        <el-empty :image-size="80" style="height: 152px;font-size: 12px" description="找不到您所需要的数据！" />
      </div>
      <div style="font-size: 20px;text-align: right;padding:0 6px 14px 0px;">
        <span @click="goToPreviousPage()" class="el-icon-arrow-up"></span>
        <text style="width: 10px;padding: 10px"></text>
        <span @click="goToNextPage()" class="el-icon-arrow-down"></span>
      </div>
    </div>
    <div style="display:flex;">
      <div class="top-div" style="width: 50%;margin: 0 0 30px 30px;">
        <div style="display: flex">
          <div style="margin-bottom: 20px;">
            <span style="font-size: 15px;font-weight: bold;">目标完成百分比</span>
          </div>
          <div>
            <el-date-picker
                size="mini"
                :clearable="false"
                style="width: 120px;margin-left: 20px"
                v-model="pieChartChoiceDate"
                @change="choiceDate('pieChart')"
                type="month"
                placeholder="请选择月份"
            />
          </div>
        </div>
        <div class="pie-chart">
          <div v-if="pieChartLoading == false">
            <el-tooltip placement="right">
              <template #content> 目标：{{pieChartData.targetNumberCount}}<br />
                完成率：{{getPercent(pieChartData.accomplishTargetNumberCount,pieChartData.targetNumberCount)}}%<br />
                已完成：{{pieChartData.accomplishTargetNumberCount}}<br />
                可点击右侧部门查看子部门！
              </template>
              <el-progress :stroke-width="14" type="circle" :percentage="getPercent(pieChartData.accomplishTargetNumberCount,pieChartData.targetNumberCount)">
                <template #default="{ percentage }">
                  <div style="font-size: 10px" class="percentage-value">{{ percentage }}%</div>
                  <span style="font-size: 10px" class="percentage-label">已完成</span>
                </template>
              </el-progress>
            </el-tooltip>
          </div>
          <el-skeleton style="width: 120px;" v-else animated>
            <template #template>
              <el-skeleton-item style="width: 120px;height: 120px" variant="circle"/>
            </template>
          </el-skeleton>
          <div v-if="pieChartLoading == false" style="width: 440px;overflow: auto;height: 260px">
            <div v-for="(item,index) in pieChartData.deptId" :key="index">
              <div style="display: flex;margin-bottom: 16px;justify-content: flex-end;" @click="getPieChart(item)">
                <div style="font-size: 13px;font-weight: bold">{{pieChartData.deptName[index]}}</div>
                <div style="display:flex;">
                    <div style="margin-left: 20px;width: 230px">
                      <el-progress
                          :text-inside="true"
                          :percentage="getPercent(pieChartData.accomplishTargetNumber[index],pieChartData.targetNumber[index]) > 100 ? 100 : getPercent(pieChartData.accomplishTargetNumber[index],pieChartData.targetNumber[index])"
                          :stroke-width="17">
                        <span style="font-size: 8px">{{pieChartData.accomplishTargetNumber[index] == null ? 0 : pieChartData.accomplishTargetNumber[index]}}</span>
                      </el-progress>
                    </div>
                    <div style="font-size: 10px;margin-left: 10px;font-weight: bold;width: 60px;text-align: right">
                      {{ getPercent(pieChartData.accomplishTargetNumber[index],pieChartData.targetNumber[index]) }}%
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-for="(item,index) in 8" :key="index">
              <el-skeleton style="width: 400px">
                <template #template>
                  <div style="padding: 8px">
                    <div style="display: flex;align-items: center;">
                      <el-skeleton-item variant="text" style="margin-right: 16px;width: 24%" />
                      <el-skeleton-item variant="text" style="margin-right: 16px;width: 60%" />
                      <el-skeleton-item variant="text" style="width: 16%;margin-left: 10px;" />
                    </div>
                  </div>
                </template>
              </el-skeleton>
            </div>
          </div>

        </div>
      </div>
      <div class="top-div" style="width: 50%;margin: 0 30px 30px 30px;">
        <div style="display:flex">
          <div style="margin-bottom: 20px;">
            <span style="font-size: 15px;font-weight: bold;">目标完成率</span>
          </div>
          <div>
            <el-date-picker
                size="mini"
                :clearable="false"
                style="width: 120px;margin-left: 20px"
                @change="choiceDate('histogram')"
                v-model="histogramChoiceDate"
                type="month"
                placeholder="请选择月份"
            />
          </div>
        </div>
        <pillars-view style="height: 400px;" v-on:histogramDetailed="histogramDetailed" :series="dataList" :project="dataKey.name"/>
      </div>
    </div>
  </div>
</template>

<script>
import PillarsView from "@/views/statistical/component/PillarsView";
import {statistics,ranking,pieChart} from "@/api/performanceTarget";
import {listXcxDept} from "@/api/dept";
export default {
  name: "TargetStatistics",
  data() {
    return {
      deptList:[],
      choiceDept:[],
      rankingLogin:true,
      noNext:false,
      noUp:false,
      rankingChoiceDate:new Date(),
      ranking:{
        deptList:[],
        month: 7,
        year: 2023,
        sort:1
      },

      histogramChoiceDate:new Date(),
      query:{
        deptId:null,
        month: 7,
        year: 2023
      },

      pieChartChoiceDate:new Date(),
      pieChartData:{},
      pieChartLoading:true,
      pieChartQuery:{
        deptId:null,
        month: 7,
        year: 2023
      },

      visibleRanking:[],
      visibleIndex:0,
      rankingList:[],
      dataList:[],
      dataKey:{}
    }
  },
  components: { PillarsView},
  methods:{
    getYearAndMonth(val) {
      let date = new Date(val)
      let month = date.getMonth() + 1;
      month = month < 10 ? ('0' + month) : month;
      return [date.getFullYear(),month]
    },
    async choiceDate (val) {
      if (val == 'pieChart') {
       this.getPieChart();
       return
      }
      if (val == 'histogram'){
        this.getData();
        return
      }
      if (val == 'ranking'){
        this.visibleIndex = 0;
        await this.getRanking();
        await this.goToNextPage();
        return
      }
    },
    /**
     * 处理数据分页
     */
    getCurrentData() {
      let that = this;
      this.rankingLogin = true;
      setTimeout(function(){
        let start = (that.visibleIndex - 1) * 5;
        let end = start + 5;
        that.visibleRanking = that.rankingList.slice(start, end);
        that.rankingLogin = false;
      }, 800);
    },
    /**
     * 下一页
     */
    goToNextPage() {
      this.visibleIndex += 1;
      let maxPage = Math.ceil(this.rankingList.length / 5);
      if (this.visibleIndex > maxPage) {
        this.visibleIndex = maxPage; // 防止超出最大页数
      }else {
        this.noNext = true;
      }
      this.getCurrentData();
    },
    /**
     * 上一页
     */
    goToPreviousPage() {
      this.visibleIndex -= 1;
      if (this.visibleIndex < 1) {
        this.visibleIndex = 1; // 防止页数变为负数
      }else {
        this.noUp = true;
      }
      this.getCurrentData();
    },
    /**
     * 获取柱状图数据
     */
    getData(){
      if (this.pieChartChoiceDate != null && this.pieChartChoiceDate != undefined) {
        let date = this.getYearAndMonth(this.histogramChoiceDate);
        this.query.year = date[0];
        this.query.month = date[1];
      }else {
        this.query.year = null;
        this.query.month = null;
      }
      statistics(this.query).then(val => {
        let dataList = [
          {data: val.data.targetNumber, name: "业绩目标", type: "bar"},
          {data: val.data.accomplishTargetNumber, name: "完成情况", type: "bar"},
      ]
        this.dataList=dataList;
        this.dataKey = {name:val.data.deptName,id:val.data.deptId}
      })
    },
    /**
     * 柱状图点击事件
     * @param val
     */
    histogramDetailed(val){
      if (this.query.deptId == this.dataKey.id[val]){
        this.query.deptId = null;
      }else {
        this.query.deptId = this.dataKey.id[val];
      }
      this.getData();
    },
    async setQuery(index){
      this.ranking.sort = index;
      this.visibleIndex = -1;
      await this.getRanking();
      await this.goToNextPage();
    },
    /**
     * 获取排名数据
     */
    async getRanking(){
      if (this.rankingChoiceDate != null && this.rankingChoiceDate != undefined){
        let date = this.getYearAndMonth(this.rankingChoiceDate);
        this.ranking.year = date[0];
        this.ranking.month = date[1];
      }else {
        this.ranking.year = null;
        this.ranking.month = null;
      }
      this.rankingLogin = true;
      console.log(this.choiceDept)
      this.choiceDept.forEach((item) => {
        this.ranking.deptList.push(item[item.length - 1])
      })
       await ranking(this.ranking).then(val => {
        this.rankingLogin = false;
        this.rankingList = val.data;
      })
    },
    /**
     * 计算num占total的百分百多少
     * @param num 实际完成
     * @param total 中目标
     * @returns {number}
     */
    getPercent(num, total) {
      if ((num == null || total == null) || (num == 0 || total == 0)){
        return 0;
      }
      return (Math.round(num / total * 10000) / 100.00);// 小数点后两位百分比
    },

    getPieChart(id){
      if (this.pieChartChoiceDate != null && this.pieChartChoiceDate != undefined){
        let date = this.getYearAndMonth(this.pieChartChoiceDate);
        this.pieChartQuery.year = date[0];
        this.pieChartQuery.month = date[1];
      }else {
        this.pieChartQuery.year = null;
        this.pieChartQuery.month = null;
      }
      this.pieChartLoading = true;
      this.pieChartQuery.deptId = id;
      pieChart(this.pieChartQuery).then(val => {
        if (val.data != null){
          this.pieChartData = val.data;
          this.pieChartLoading = false;
        }else {
          let that = this;
          setTimeout(function(){
            that.pieChartLoading = false;
          }, 800);
        }
      })
    },
    /**
     * 部门数据递归处理无效空数组
     * @param commodityType
     * @returns {*}
     */
    getTypeList(commodityType) {
      if (commodityType!=null){
        commodityType.forEach(items => {
          if (items!=null){
            if (items.children!=null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
  },

  async created(){
    listXcxDept().then(res => {
      this.deptList = this.getTypeList(res.data);
    })
    this.getData();
    this.getPieChart();

    await this.getRanking();
    await this.goToNextPage();
  }
}
</script>

<style scoped>
.top-div{
  box-shadow: 0px 1px 20px 1px #f5f5f5;
  padding: 20px 20px 0 20px;
  border-radius: 16px;
}
.ranking-item:hover {
  box-shadow: 0px 1px 20px 1px #e2e2e2;
}
.ranking-item{
  padding: 20px 20px;
  border-radius: 20px;
  margin: 20px 20px;
  width: 220px;
  font-size: 14px;
  box-shadow: 0px 1px 30px 1px #f8f8f8;
}
.items{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.item {
  padding: 20px 20px;
  border-radius: 20px;
  margin: 20px 0;
  width: 220px;
  font-size: 14px;
  box-shadow: 0px 1px 10px 1px #f5f5f5;
}
.flex-around{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.sort{
  width: 32px;
  height: 32px;
  position: absolute;
  top: -4px;
  right: 0;
}
.pie-chart{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  padding-left: 50px
}
</style>
<style lang="scss">
.el-popover.my-el-popover {
  width: 20px;
}
.el-cascader__tags {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}
</style>